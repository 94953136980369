import get from "lodash.get"
import set from "lodash/set.js"
import { labelFromName } from "./stringUtils.mjs"
import { formatCurrency } from "./currencyUtils.mjs"
import { formatPercentage } from "./numberUtils.mjs"
import { formatDate, formatDateTime } from "./dateUtils.mjs"

export const PROPS_ = "props_"

export function getFieldProps(obj, field) {
  if (!obj || !field) return {}

  const fieldPropsPath = getFieldPropsPath(field)
  return get(obj, fieldPropsPath, {}) || {}
}

export function getFieldPropsPath(fieldName) {
  if (!fieldName) return

  // "addresses[0].address1" => "addresses[0].props_address1"
  // "obj.nestedArray[0].nestedField.field" => "obj.nestedArray[0].nestedField.props_field"
  if (fieldName.includes(".")) {
    const nestedField = fieldName.split(".").pop() // "address1" // "field"
    return `${fieldName.replace(nestedField, "")}${PROPS_}${nestedField}`
  }

  return PROPS_ + fieldName
}

export const setMin = (obj, fieldNames, min) => setFieldProp(obj, fieldNames, "min", min)
export const setMax = (obj, fieldNames, max) => setFieldProp(obj, fieldNames, "max", max)
export const setType = (obj, fieldNames, type) => setFieldProp(obj, fieldNames, "type", type)
export const setLabel = (obj, fieldNames, label) => setFieldProp(obj, fieldNames, "label", label)
export const setValue = (obj, fieldNames, value) => setFieldProp(obj, fieldNames, "value", value)
export const setQuery = (obj, fieldNames, query) => setFieldProp(obj, fieldNames, "query", query)
export const setSelect = (obj, fieldNames, select) => setFieldProp(obj, fieldNames, "select", select)
export const setWarning = (obj, fieldNames, warning) => setFieldProp(obj, fieldNames, "warningFormat", warning)
export const setError = (obj, fieldNames, error) => setFieldProp(obj, fieldNames, "errorFormat", error)
export const setHidden = (obj, fieldNames, isHidden) => setFieldProp(obj, fieldNames, "hidden", isHidden)
export const setTouched = (obj, fieldNames, isTouched) => setFieldProp(obj, fieldNames, "touched", isTouched)
export const setDisabled = (obj, fieldNames, isDisabled) => setFieldProp(obj, fieldNames, "disabled", isDisabled)
export const setReadOnly = (obj, fieldNames, isReadOnly) => setFieldProp(obj, fieldNames, "readOnly", isReadOnly)
export const setMultiple = (obj, fieldNames, isMultiple) => setFieldProp(obj, fieldNames, "multiple", isMultiple)
export const setMandatory = (obj, fieldNames, isMandatory) => setFieldProp(obj, fieldNames, "mandatory", isMandatory)
export const setShowMandatoryHint = (obj, fieldNames, showMandatoryHint) => setFieldProp(obj, fieldNames, "showMandatoryHint", showMandatoryHint)
export const setColProps = (obj, fieldNames, colProps) => setFieldProp(obj, fieldNames, "colProps", colProps)
export const setAdvancedSearch = (obj, fieldNames, advancedSearch) => setFieldProp(obj, fieldNames, "advancedSearch", advancedSearch)

export const getWarning = (obj, fieldName) => getFieldProp(obj, fieldName, "warningFormat")
export const getError = (obj, fieldName) => getFieldProp(obj, fieldName, "errorFormat")
export const isHidden = (obj, fieldName) => getFieldProp(obj, fieldName, "hidden")
export const isTouched = (obj, fieldName) => getFieldProp(obj, fieldName, "touched")
export const isDisabled = (obj, fieldName) => getFieldProp(obj, fieldName, "disabled")
export const isMandatory = (obj, fieldName) => getFieldProp(obj, fieldName, "mandatory")

export function hasErrors(obj) {
  const _hasErrors = navigateProps_(obj, (_obj, fieldName, fieldProps) => {
    // Format error
    if (fieldProps?.errorFormat) return true

    // Empty mandatory field
    if (isEmpty(_obj[fieldName]) && fieldProps?.mandatory && fieldProps?.touched) return true
  })
  return _hasErrors
}

export function isLessThanMin({ value, min }) {
  return !isEmpty(min) && value < min
}

export function isMoreThanMax({ value, max }) {
  return !isEmpty(max) && value > max
}

function checkLessThanMin({ min, value, type, currency, locale }) {
  if (!isLessThanMin({ value, min })) return
  return `Must be ≥ ${format({ value: min, type, currency, locale })}`
}

function checkMoreThanMax({ max, value, type, currency, locale }) {
  if (!isMoreThanMax({ value, max })) return
  return `Must be ≤ ${format({ value: max, type, currency, locale })}`
}

// In some cases, instanceof Date might not return true even if it is an actual Date object
function isDate(value) {
  return value instanceof Date || Object.prototype.toString.call(value) === "[object Date]"
}

function format({ value, type, currency, locale }) {
  if (type === "percentage") return formatPercentage(value, locale)
  if (type === "currency") return formatCurrency(value, locale, currency)

  if (["date", "datetime"].includes(type) || isDate(value)) {
    if (type === "datetime") return formatDateTime(value, locale)
    return formatDate(value, locale)
  }

  return value
}

export function getWarnings(obj) {
  const warnings = []

  // Get "obj" fields warning by priority from "props_" (warningFormat only)
  navigateProps_(obj, (_obj, fieldName, fieldProps, fieldPath) => {
    // Warning if field has "warningFormat" prop
    if (fieldProps?.warningFormat) {
      warnings.push(toWarning(fieldPath, fieldProps.warningFormat))
    }
  })

  if (!warnings.length) return
  return Array.from(new Set(warnings))
}

export function getErrors(obj, { loc, locale }) {
  const errors = []

  // Retrieve the errors from "obj.documents" so that you can scroll to them
  // This is to support the hack/workaround done in <DocumentComponent/> when <DocumentsComponent/> is injected as a card in a page
  // See <DocumentComponent/>: data-model-field-path={`documents.${type}`} in basikon-client
  if (typeof obj?.documents === "object" && !Array.isArray(obj?.documents)) {
    for (const documentType in obj.documents) {
      if (!obj.documents?.[documentType]?.errorFormat) continue

      errors.push({
        modelFieldPath: `documents.${documentType}`,
        value: obj.documents[documentType].errorFormat,
      })
    }
  }

  // Get "obj" fields error by priority from "props_" (1. errorFormat, 2. min/max, 3. mandatory)
  navigateProps_(obj, (_obj, fieldName, fieldProps, fieldPath) => {
    const value = _obj[fieldName]

    // 1. Error if field has "errorFormat" prop
    if (fieldProps?.errorFormat) {
      errors.push(toError(fieldPath, fieldProps.errorFormat))
    }
    // 2. Error if field is not empty && (< min || > max)
    else if (!isEmpty(value)) {
      const label = fieldProps.label || loc(labelFromName(fieldName))

      if (isLessThanMin({ value, min: fieldProps.min })) {
        const formattedMin = format({ value: fieldProps.min, type: fieldProps.type, currency: fieldProps.currency, locale })
        errors.push(toError(fieldPath, loc("$ must be ≥ $", label, formattedMin)))
      }

      if (isMoreThanMax({ value, max: fieldProps.max })) {
        const formattedMax = format({ value: fieldProps.max, type: fieldProps.type, currency: fieldProps.currency, locale })
        errors.push(toError(fieldPath, loc("$ must be ≤ $", label, formattedMax)))
      }
    }
    // 3. Error if field is empty && mandatory && touched
    else if (isEmpty(value) && fieldProps.mandatory && fieldProps.touched) {
      const label = fieldProps.label || loc(labelFromName(fieldName))
      errors.push(toError(fieldPath, loc("$ is mandatory", label)))
    }
  })

  if (!errors.length) return
  return Array.from(new Set(errors))
}

function toWarning(fieldPath, warningMessage) {
  if (fieldPath) return { modelFieldPath: fieldPath, value: warningMessage }
  return warningMessage
}

function toError(fieldPath, errorMessage) {
  if (fieldPath) return { modelFieldPath: fieldPath, value: errorMessage }
  return errorMessage
}

export function checkErrors(obj, onSetState, { skipWorkflowErrors = false } = {}) {
  let hasTouched, hasEmptyMandatoryFields, hasInvalidMinMaxFields
  const errors = []

  navigateProps_(obj, (_obj, fieldName, fieldProps, fieldPath) => {
    const value = _obj[fieldName]

    // Check whether there are any "errorFormat" present in the fields of the "obj"
    if (fieldProps?.errorFormat && (!skipWorkflowErrors || !fieldProps.isWorkflowTransitionError)) {
      console.error(`checkErrors(): Error on field "${fieldName}": ${fieldProps.errorFormat} (path: ${fieldPath || "."})`)
      errors.push(toFieldPath(fieldPath, fieldProps.errorFormat))
    }

    // Check whether there are empty "mandatory" fields
    if (isEmpty(value) && fieldProps?.mandatory && fieldProps?.touched) {
      console.error(`checkErrors(): Empty mandatory field "${fieldName}" (path: ${fieldPath || "."})`)
      hasEmptyMandatoryFields = true
      errors.push(toError(fieldPath))
    }

    // Check whether there are invalid "min"/"max" fields
    const minError = checkLessThanMin({ min: fieldProps.min, value, type: fieldProps.type, currency: obj.currency })
    const maxError = checkMoreThanMax({ max: fieldProps.max, value, type: fieldProps.type, currency: obj.currency })
    if (minError || maxError) {
      console.error(`checkErrors(): Invalid value in field "${fieldName}": ${minError || maxError} (path: ${fieldPath || "."})`)
      hasInvalidMinMaxFields = true
    }

    // Touch empty "mandatory" fields to show the errors
    if (fieldProps?.mandatory && isEmpty(value) && !fieldProps?.touched) {
      console.log(`checkErrors(): Touched mandatory empty field "${fieldName}" (path: ${fieldPath || "."})`)
      fieldProps.touched = true
      hasTouched = true
      errors.push(toError(fieldPath))
    }
  })

  obj.errors = errors

  if (hasTouched || errors.length) {
    onSetState(obj)
    return true
  }

  return hasTouched || hasEmptyMandatoryFields || hasInvalidMinMaxFields || errors.length > 0
}

function getFieldProp(obj, fieldName, propName) {
  if (!obj || !fieldName) return

  const fieldPropsPath = getFieldPropsPath(fieldName)
  return get(obj, `${fieldPropsPath}.${propName}`)
}

export function setFieldProp(obj, fieldNames, propName, propValue) {
  if (!Array.isArray(fieldNames)) fieldNames = [fieldNames]

  for (const fieldName of fieldNames) {
    if (!fieldName) continue

    const fieldPropsPath = getFieldPropsPath(fieldName)

    obj = obj || {}
    set(obj, `${fieldPropsPath}.${propName}`, propValue)
  }
}

export function setFieldProps(obj, fieldNames, props) {
  if (!Array.isArray(fieldNames)) fieldNames = [fieldNames]

  for (const fieldName of fieldNames) {
    if (!fieldName) continue

    const fieldPropsPath = getFieldPropsPath(fieldName)

    obj = obj || {}
    for (const propName in props || {}) set(obj, `${fieldPropsPath}.${propName}`, props[propName])
  }
}

function navigateProps_(obj, propsFunction, path) {
  for (const key in obj) {
    const fieldPath = toFieldPath(path, key)

    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (key.startsWith(PROPS_)) {
        const fieldName = key.replace(PROPS_, "")
        const fieldProps = obj[key] || {}
        const stop = propsFunction(obj, fieldName, fieldProps, fieldPath)
        if (stop) return stop
      }
      // If the property is an object, recursively navigate it
      else if (typeof obj[key] === "object") {
        const stop = navigateProps_(obj[key], propsFunction, fieldPath)
        if (stop) return stop
      }
    }
  }
}

function toFieldPath(path, key) {
  const isIndex = Number(key) >= 0
  const isProps_ = key.startsWith(PROPS_)
  const field = isProps_ && key.replace(PROPS_, "")

  if (isIndex && path) return `${path}[${key}]`
  if (isProps_ && path) return `${path}.${field}`
  if (isIndex && !path) return key
  if (isProps_ && !path) return field
  if (path) return `${path}.${key}`
  return key
}

/**
 * @param {string} value
 * @returns {boolean} Whether the given value is empty.
 */
export function isEmpty(value) {
  return (
    (!value && value !== 0) ||
    (Array.isArray(value) && value.length === 0) ||
    (value.constructor.name === "Object" && Object.keys(value).length === 0)
  ) // Other objects like dates can have `typeof value === "object"` so we need to check the constructor
}

export function hasChanged(prevObj, obj, field) {
  if (!prevObj || !obj) return false
  if (isDate(obj[field])) return obj[field].getTime() !== prevObj[field]?.getTime()
  return obj[field] !== prevObj[field]
}
