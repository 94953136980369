import React from "react"
import { Row, Modal } from "react-bootstrap"

import PanelInner from "@/_components/PanelInner"
import CustomButton from "@/_components/CustomButton"
import FormInputExtended from "@/_components/FormInputExtended"
import ButtonWithTooltip from "@/_components/ButtonWithTooltip"
import { checkErrors } from "basikon-common-utils"
import { loc } from "@/_services/localization"

class EntityBankAccountsModal extends React.Component {
  constructor(props) {
    super(props)

    const { entity } = props

    this.state = {
      prevBankAccounts: (entity?.bankAccounts || []).map(bankAccount => ({ ...bankAccount })),
    }
  }

  handleClose = () => {
    const { prevBankAccounts } = this.state
    const { handleSetEntityState, onClose } = this.props

    handleSetEntityState({ bankAccounts: prevBankAccounts })
    onClose()
  }

  handleSave = () => {
    const { entity, handleSetEntityState, onClose } = this.props
    if (checkErrors(entity, handleSetEntityState)) return
    onClose()
  }

  handleBankAccountAction = (event, action, index) => {
    const { entity, handleSetEntityState } = this.props
    const copyBankAccounts = (entity?.bankAccounts || []).map(bankAccount => ({ ...bankAccount })) // Copy to keep prevState

    if (action === "add") copyBankAccounts.push({})
    else if (action === "delete") copyBankAccounts.splice(index, 1)
    else if (action === "setFirst") {
      event.stopPropagation()

      const bankAccount = copyBankAccounts[0]
      copyBankAccounts[0] = copyBankAccounts[index]
      copyBankAccounts[index] = bankAccount
    }

    handleSetEntityState({ bankAccounts: copyBankAccounts })
  }

  render() {
    const {
      entity,
      readOnly,
      modelPath,
      showSort = true,
      showSepaMandates,
      showDelete = true,
      showSepaMandateAdd = true,
      showSepaMandateDelete = true,
      showCollectionMethods = false,
      showSepaMandateDuplicate = true,
      showPreferredWithdrawalDate = true,
      handleSetEntityBankAccountState,
    } = this.props

    return (
      <Modal show={true} onHide={() => this.handleClose()} bsSize="large" backdrop="static" className="person-edit-bank-accounts-modal">
        <Modal.Header closeButton>
          <Modal.Title>{loc`Bank accounts`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(entity?.bankAccounts || []).map((bankAccount, index) => {
            return (
              <PanelInner
                key={index}
                collapse={bankAccount.isInactive ? true : false}
                title={
                  <>
                    {bankAccount.isInactive ? <strike>{bankAccount.name}</strike> : bankAccount.name}
                    {index > 0 && (
                      <>
                        {showDelete && (
                          <ButtonWithTooltip
                            simple
                            pullRight
                            bsSize="xs"
                            bsStyle="danger"
                            btnClassName="flex-center"
                            className="icn-xmark icn-xs"
                            onClick={event => this.handleBankAccountAction(event, "delete", index)}
                          />
                        )}
                        {showSort && (
                          <CustomButton
                            bsSize="xs"
                            simple
                            pullRight
                            className="flex-center"
                            iconClassName="icn-arrow-up icn-xs"
                            label="Make it first/default"
                            onClick={event => this.handleBankAccountAction(event, "setFirst", index)}
                          />
                        )}
                      </>
                    )}
                  </>
                }
              >
                <Row>
                  <FormInputExtended
                    type="bankAccount"
                    obj={bankAccount}
                    readOnly={readOnly}
                    modelPath={(modelPath ? `${modelPath}.` : "") + `bankAccounts[${index}]`}
                    onSetState={patch => handleSetEntityBankAccountState({ patch, index })}
                    fields={[
                      { field: "name", mandatory: true, colProps: { xs: 12, md: 10 } },
                      { field: "isInactive", type: "checkbox", label: "Inactive", colProps: { xs: 12, md: 2 } },
                      { field: "bankIban", debounce: true, colProps: { xs: 12, sm: 6 } },
                      { field: "bankBic", colProps: { xs: 12, sm: 3 } },
                      { field: "bankName", colProps: { xs: 12, sm: 3 } },
                      { field: "bankOwner", colProps: { xs: 12, sm: 6 } },
                      { field: "bankOpeningDate", colProps: { xs: 12, sm: 3 } },
                      { field: "bankPreferredWithdrawalDate", hidden: !showPreferredWithdrawalDate, colProps: { xs: 12, sm: 3 } },
                      { field: "externalReference", colProps: { xs: 12, sm: 3 } },
                      { field: "sepaCreditorIdentifier", label: "SEPA creditor identifier", colProps: { xs: 12, sm: 3 } },
                      {
                        field: "collectionMethods",
                        hidden: !showCollectionMethods,
                        multiple: true,
                        select: "paymentMode",
                        colProps: { xs: 12, sm: 6 },
                      },
                      { field: "sepaMandates", hidden: !showSepaMandates, showSepaMandateAdd, showSepaMandateDelete, showSepaMandateDuplicate },
                    ]}
                  />
                </Row>
              </PanelInner>
            )
          })}
          {!readOnly && (
            <legend>
              <CustomButton
                fill
                bsStyle="primary"
                bsSize="small"
                className="flex-center"
                onClick={event => this.handleBankAccountAction(event, "add")}
              >
                <i className="icn-plus icn-xs mr-5px" />
                {loc("Add")}
              </CustomButton>
            </legend>
          )}
        </Modal.Body>
        <Modal.Footer>
          <CustomButton bsSize="sm" onClick={() => this.handleClose()} label="Close" />
          <CustomButton bsStyle="primary" bsSize="sm" fill onClick={() => this.handleSave()} label="Save" hidden={readOnly} />
        </Modal.Footer>
      </Modal>
    )
  }
}

export default EntityBankAccountsModal
